import React, {useEffect} from "react";

import config from "../config";

import Main from "./main/main";
import Advantages from "./advantages/advantages";
import Products from "./products/products";
import About from "./about/about";
import Partners from "./about/modules/partners/partners";
import BlockForm from "./forms/block-form";
import Links from "./links/links";

function Home(props) {
    useEffect(() => {
        document.title = config.meta_title
        const the_description = document.querySelector("meta[name='description']");
        the_description.setAttribute("content", config.meta_description);
    });
    return (
        <>
            <Main modal={props.modal}/>
            <Advantages/>
            <Products modal={props.modal}/>
            <About/>
            <Partners/>
            <BlockForm/>
            <Links/>
        </>
    );
}

export default Home;