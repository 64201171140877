const config = {
    url: 'https://keramzit-ass.ru/', 
    company: 'ООО «Нерудком»',
    meta_title: 'Керамзитовый гравий с доставкой по Москве и МО - 24/7',
    meta_description: 'Керамзитовый гравий напрямую от производителя предлагает компания ООО «Нерудком» . У нас свой автопарк. Любая форма оплаты. Звони!',
    title: 'Керамзит',
    descriptor: 'напрямую от производителя от 1200 руб./м3',
    offer: 'Доставка до Вашего объекта – от 3 часов',
    time: 'Пн-Вс 8:00 — 23:00',
    phone: '+7(926) 452 03 90',
    email: 'zakaz@nerudkom.ru',
    INN: '7708422517',
    OGRN: '1237700526359',
    whatsapp: '+79263900390',
    telegram: 'nerudkom_bot',
    bitrix24WebHook: 'https://alfaspetsstroy.bitrix24.ru/rest/920/ufheq23denm5t04a',
    pole_phone: 'UF_CRM_2928E19C70542',
    pole_name: 'UF_CRM_1623227175451',
    pole_value: 'UF_CRM_82F235BF1C13D',
    pole_address: 'UF_CRM_1628014047977',
    CATEGORY_ID: 0,
    funnel_id: '2'
}

export default config