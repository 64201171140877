import React, { useState } from "react";

import config from "../../../../config";

import './us.scss'
import './adaptive.scss'


function Us() {
    const [page, setPage] = useState(1);
    return (
        <React.Fragment>
                <div className="aboutUs">
                    <div className="container">
                        <div className="content">
                            <div className="text">
                                <div className="title">
                                    <span>О компании</span>
                                    <span><b>{config.company} —</b><br/>надежный партнер вашего бизнеса</span>
                                </div>
                                <div className="description">
                                Продажа и доставка сыпучих строительных материалов – приоритетная деятельность нашей компании. За время существования нам удалось разработать универсальную схему покупки и доставки реализуемой продукции: покупатель оставляет заявку на приобретение сырья, наши специалисты обрабатывают заказ в тот же день
                                </div>
                            </div>
                            <div className="line"/>
                        </div>
                    </div>
                    <div className="slider">
                        <div className="container">
                            <div className="content">
                                <div className="btns">
                                    <div className={page === 1 ? "btn active" : "btn"} onClick={()=>{setPage(1)}}>Керамзит</div>
                                </div>
                                <div className="pages">
                                    {
                                        page === 1 ? (
                                            <>
                                                <span className="title">Керамзит</span>
                                                <p>Керамзит — лёгкий, с большой степенью пор, материал нашедший широкое применение в строительстве. Производится посредством обжига глины. Часто для этих же целей используют глинистый сланец. Получаемый в результате керамзитовый гравий приобретает овальную, неоднородную форму. Существует также понятие: керамзитовый щебень – отличием материала становится лишь его геометрия, “шарики” больше похожи на кубики с острыми гранями и не ровными углами</p>
                                                <span className="title">Применение керамзита</span>
                                                <p>Ввиду незначительной стоимости и привлекательным свойствам, керамзит очень востребованный строительный и теплоизолирующий наполнитель. Материал не требует специфичных навыков в работе, легкий, недорогой, доступный, именно поэтому сфера его применения необычайно широкая и разнообразная:</p>
                                                <div className="numberText">
                                                    <span>производство облицовочного и высоко-пустотного керамического кирпича;</span>• 
                                                    <span>• производство керамзитобетонных блоков;</span>
                                                    <span>• утепление полов, стен, чердачных перекрытий, как следствие снижение теплопотерь на 70%;</span>
                                                    <span>• применение в качестве пористого заполнителя в изготавливании легкого бетона;</span>
                                                    <span>• повсеместно в строительном комплексе: жилые здания, промышленные сооружения, дачные постройки и многое другое, где востребован этот недорогой материал;</span>
                                                    <span>• в качестве дизайнерских решений на придомовом участке – различные тротуарные плитки, насыпные конструкции;</span>
                                                    <span>• в сельском хозяйстве стабилизируется показатель водного баланса после включения в грунт зерен керамзита;</span>
                                                    <span>• в очистных сооружениях, участвует в грубой очистке при помощи фильтров.</span>
                                                </div>
                                                <span className="title">Виды керамзита</span>
                                                <div className="numberText">
                                                    <span>• Керамзитовый гравий фракции 5-40</span>
                                                    <span>• Керамзитовый щебень фракции 5-40</span>
                                                    <span>• Керамзитовый песок фракции 0-5</span>
                                                </div>
                                                <span className="title">Преимущества керамзита?</span>
                                                <div className="numberText">
                                                    <span>• Не подвержен гниению;</span>
                                                    <span>• Не потеряет свои свойства и не будет разрушаться при заморозке;</span>
                                                    <span>• Долговечный;</span>
                                                    <span>• Не впитывает воду;</span>
                                                    <span>• Не имеет в составе вредоносных примесей;</span>
                                                    <span>• Огнеустойчивый;</span>
                                                    <span>• Высокая степень прочности.</span>
                                                </div>
                                            </>
                                        ): setPage(1)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
}

export default Us;