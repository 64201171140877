const productsArray = {
    main: [
        {
            title: 'Керамзит',
            descriptor: 'фракции 5-10',
            price: 2000
        },
        {
            title: 'Керамзит',
            descriptor: 'фракции 10-20',
            price: 1300
        },
        {
            title: 'Керамзит',
            descriptor: 'фракции 20-40',
            price: 1300
        },
        {
            title: 'Керамзитовый песок',
            descriptor: 'фракции 0-5',
            price: 2300
        },
        {
            title: 'Керамзит',
            descriptor: 'речной',
            price: 1400
        },
        {
            title: 'Керамзитовый щебень',
            descriptor: 'фракции 5-40',
            price: 1200
        }    
    ]
}
export default productsArray